<template>
	<div>
		<CRow>
			<CCol md="12">
				<CCard>
					<CCardHeader>
						<CRow>
							<CCol md="8">
								<h4>Edit FAQ</h4>
							</CCol>
						</CRow>
					</CCardHeader>
					<CForm v-on:submit.prevent="storeProcess" class="fwdwd">
						<CCardBody>
							<div class="form-group">
								<label for=""><b>Tipe FAQ <span class="text-danger">*</span></b></label>
								<v-select :options="list_tipe_faq" label="tipe_faq_nama" v-model="tipe_faq"></v-select>
							</div>
							<div class="form-group">
								<label for=""><b>Pertanyaan <span class="text-danger">*</span></b></label>
								<input type="text" class="form-control" v-model="faq_pertanyaan">
							</div>
							<div class="form-group">
								<label for=""><b>Jawaban <span class="text-danger">*</span></b></label>
								<template>
						   
								    <vue-editor v-model="faq_jawaban"></vue-editor>
								   
								</template>
							</div>
						</CCardBody>
						<CCardFooter>
							<div class="text-right">
								<button type="submit" class="btn btn-primary text-white btn-hover-outline-primary"><i class="fa fa-save"></i> Simpan</button>
							</div>
						</CCardFooter>
					</CForm>
				</CCard>
			</CCol>
		</CRow>
	</div>
</template>
<script>
import { VueEditor } from 'vue2-quill-editor'
	export default {
		name: "EditFaq",
		components: {
		    VueEditor
		},
		data() {
			return {
				tipe_faq: '',
				faq_pertanyaan: '',
				faq_jawaban: '',
				list_tipe_faq: [],
				idFaq: this.$route.params.idFaq,
			};
		},
		methods: {
			storeProcess: function () {
				let self = this;
				Swal.fire({
					title: '<i class="fa fa-refresh fa-spin"></i>',
					text: "Loading...",
					showConfirmButton: false,
					allowOutsideClick: false,
				});
				axios.put(
                    this.apiLink + "api/crud/faq",
                    {
                        id: this.idFaq,
                        tipe_faq_id: this.tipe_faq.id,
						faq_pertanyaan: this.faq_pertanyaan,
						faq_jawaban: this.faq_jawaban,
                        
                    },
                    {
                        headers: {
                            Authorization: "Bearer " + this.access_token,
                        },
                    }
                )
				.then(function (response) {
					var res_data = response.data;
					// console.log(res_data);
					if (res_data.meta.code != "200") {
						Swal.fire({
							icon: 'error',
							title: 'Oops...',
							text: res_data.data.message
						});
					}
					else{
						let timerInterval;
						Swal.fire({
							icon: "success",
							title: "Berhasil",
							text: "Anda akan diarahkan ke halaman master faq segera",
							timer: 2000,
							timerProgressBar: true,
							showCancelButton: false,
							showConfirmButton: false,
							didOpen: () => {
								timerInterval = setInterval(() => {
									const content = Swal.getContent();
									if (content) {
										const b = content.querySelector("b");
										if (b) {
											b.textContent = Swal.getTimerLeft();
										}
									}
								}, 100);
							},
							willClose: () => {
								clearInterval(timerInterval);
							},
						}).then((result) => {
							/* Read more about handling dismissals below */
							if (result.dismiss === Swal.DismissReason.timer) {
								self.$router.push({name: 'AllFaq'});
							}
						});
					}
				});
			},
		},
		created() {
            Swal.fire({
                title: '<i class="fa fa-refresh fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
                allowOutsideClick: false,
            });
            // Get Master Tanaman
            axios.get(this.apiLink + "api/master/tipe_faq", {
                params: {
                },
                headers: {
                    Authorization: "Bearer " + this.access_token,
                },
            })
            .then((response) => {
                var res_tipe_faq = response.data;
                var res_tipe_faq_data = res_tipe_faq.data;
                if (res_tipe_faq.meta.code != "200") {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: res_tipe_faq.data.message
                    });
                }
                else{
                    this.list_tipe_faq = res_tipe_faq_data.master;
                    // console.log(res_tipe_faq_data.master);
                    
                }
            });
            axios.get(this.apiLink + "api/crud/faq", {
                params: {
                    id: this.idFaq,
                },
                headers: {
                    Authorization: "Bearer " + this.access_token,
                },
            })
            .then((response) => {
                var res_faq = response.data;
                var res_faq_data = res_faq.data[0].data[0];
                if (res_faq.meta.code != "200") {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: res_faq.data.message
                    });
                }
                else{
                    Swal.close();
                    this.tipe_faq = res_faq_data.tipe_faq;
                    // console.log(res_faq_data);
					this.faq_pertanyaan = res_faq_data.faq_pertanyaan;
					this.faq_jawaban = res_faq_data.faq_jawaban;
					
                }
            });
        }
	};
</script>